<template>
  <div
    ref="container"
    class="w-lottie"
    :class="{ 'current-color': isCurrentColor }"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watchEffect } from 'vue'
import lottie, { type AnimationItem, type AnimationDirection } from 'lottie-web'

export interface StLottieProps {
  animationData: Record<string, unknown>
  loop?: number | boolean
  autoplay?: boolean
  speed?: number
  direction?: AnimationDirection
  isCurrentColor?: boolean
}

export interface StLottieEmits {
  (e: 'complete'): void
  (e: 'loopComplete'): void
  (e: 'enterFrame'): void
  (e: 'segmentStart'): void
}

const props = withDefaults(defineProps<StLottieProps>(), {
  isCurrentColor: false,
  loop: false,
  autoplay: true,
  speed: 1,
  direction: 1,
})

const emit = defineEmits<StLottieEmits>()

const container = ref<HTMLDivElement>()
const animation = ref<AnimationItem>()

onMounted(() => {
  if (!container.value) return
  animation.value = lottie.loadAnimation({
    container: container.value,
    renderer: 'svg',
    loop: props.loop,
    autoplay: props.autoplay,
    animationData: props.animationData,
  })
  animation.value.addEventListener('loopComplete', () => emit('loopComplete'))
  animation.value.addEventListener('complete', () => emit('complete'))
  animation.value.addEventListener('enterFrame', () => emit('enterFrame'))
  animation.value.addEventListener('segmentStart', () => emit('segmentStart'))
})

watchEffect(() => animation.value?.setDirection(props.direction))
watchEffect(() => animation.value?.setSpeed(props.speed))

onBeforeUnmount(() => {
  animation.value?.destroy()
})

defineExpose({
  play: () => animation.value?.play(),
  pause: () => animation.value?.pause(),
  stop: () => animation.value?.stop(),
  goToAndStop: (value: number, isFrame?: boolean) =>
    animation.value?.goToAndStop(value, isFrame),
  goToAndPlay: (value: number, isFrame?: boolean) =>
    animation.value?.goToAndPlay(value, isFrame),
})
</script>

<style scoped>
.current-color :deep(svg *) {
  fill: currentcolor;
  stroke: currentcolor;
}

:deep(svg) {
  display: flex;
}
</style>
